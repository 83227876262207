
import { Mixins, Component, Ref } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import ETable from "@/components/ETable.vue";
import { Form } from "element-ui/types";
import loading from "@/mixins/loading";
import { sendTestCommand, getDeviceStatus, sendQrCode, getSendQrcodeList, getDeviceMessage } from "@/api/tools";
import { StatusEnum } from "@/enum/device.enum";

const curDomain = `${window.location.origin}`;

@Component({
    components: { ETable }
})
export default class instructionSending extends Mixins(loading, tablePagination) {
    @Ref("validateForm") formRef: Form;
    form = {
        deviceCode: "81596900",
        instruction: "ACAC0004B3010210",
    };
    rules = {
        deviceCode: [
            { required: true, message: "请输入通讯设备编码", trigger: "blur" },
        ],
        instruction: [
            { required: true, message: "请输入需要发送的指令", trigger: "blur" },
        ]
    };
    StatusEnum = StatusEnum;
    deviceStatus = "";
    sendQrcodeList = [];
    deviceMessageList = [];
    url = curDomain + "/type.html";

    QrcodeColumns = [
        { label: "时间", prop: "createTime", width: "160px" },
        { label: "推送内容", prop: "payload" },
    ];

    messageColumns = [
        { label: "时间", prop: "createTime", width: "160px" },
        { label: "指令", prop: "payload" },
    ]

    instruction = [
        {
            label: "启动设备指令",
            value: "ACAC0005A10e10021c"
        },
        {
            label: "设备停止指令",
            value: "ACAC0004A20101FF"
        },
        {
            label: "设备暂停指令",
            value: "ACAC0004A3010200"
        },
        {
            label: "读取设备状态",
            value: "ACAC0004B201020F"
        },
        {
            label: "读取设备参数",
            value: "ACAC0004B3010210"
        },
        {
            label: "读取设备类型",
            value: "ACAC0004B101020e"
        }
    ]

    mounted() {
        this.getDeviceStatus();
    }
    send() {
        this.formRef?.validate((valid) => {
            if (valid) {
                sendTestCommand(this.form.deviceCode, this.form.instruction ).then((res) => {
                    this.$message({
                        message: "发送成功",
                        type: "success"
                    })
                }).catch((err) => {
                    this.$message({
                        message: err,
                        type: "error"
                    })
                })
            } else {
                return false;
            }
        })
    }

    getDeviceStatus() {
        this.deviceStatus = "查询中...";
        getDeviceStatus(this.form.deviceCode).then((res) => {
            this.deviceStatus = StatusEnum[res.data.status];
        }).catch((err) => {})
    }

    sendQrCode() {
        sendQrCode(this.form.deviceCode).then((res) => {
            this.$message({
                message: "发送成功",
                type: "success"
            })
        }).catch((err) => {
            this.$message({
                message: err,
                type: "error"
            })
        })
    }

    getSendQrcodeList() {
        getSendQrcodeList(this.form.deviceCode).then((res) => {
            this.sendQrcodeList = res.data;
        })
    }

    getDeviceMessage() {
        getDeviceMessage(this.form.deviceCode).then((res) => {
            if(!res.data.length) {
                this.$message({
                    message: "没有收到设备数据",
                    type: "warning"
                })
            }
            this.deviceMessageList = res.data;
        })
    }

    selectItem(item) {
        this.form.instruction = item.value;
    }
}

