import { service } from '@/ajax/request'

export const getDeviceType = () => (
    service({
        url: '/tools/iot/data/deviceType',
        method: 'POST',
    })
)

export const getDataPage = (params: any) => (
    service({
        url: '/tools/iot/data/query',
        method: 'POST',
        params
    })
)

export const sendTestCommand = (deviceCode: any, content: any) => (
    service({
        url: `/device/Command/sendTestCommand/${deviceCode}`,
        method: 'POST',
        type: 'form',
        params: { content }
    })
)

export const getDeviceStatus = (deviceCode: any) => (
    service({
        url: `/device/Command/getDeviceStatus/${deviceCode}`,
        method: 'GET',
    })
)

export const sendQrCode = (deviceCode: any) => (
    service({
        url: `/device/Command/sendQrCode/${deviceCode}`,
        method: 'GET',
    })
)

export const getSendQrcodeList = (deviceCode: any) => (
    service({
        url: `/device/Command/getSendQrcodeList/${deviceCode}`,
        method: 'GET',
    })
)

export const getDeviceMessage = (deviceCode: any) => (
    service({
        url: `/device/Command/getDeviceMessage/${deviceCode}`,
        method: 'GET',
    })
)

export const payloadParsing = (params: any) => (
    service({
        url: '/tools/iot/data/payload',
        method: 'POST',
        params
    })
)